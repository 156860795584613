<template>
  <div>
    <h3><i class="fal fa-inbox me-2"></i>Messages</h3>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <!-- BEGIN INBOX -->
          <div class="col-md-12">
            <div class="grid email">
              <div class="grid-body">
                <div class="row">
                  <!-- BEGIN INBOX MENU -->
                  <div class="col-md-3">
                    <a class="btn btn-block btn-primary btn-sm w-100"
                      ><i class="fal fa-pencil me-2"></i>New Message</a
                    >

                    <hr />

                    <div>
                      <ul class="nav flex-column">
                        <li class="active">
                          <a href="#" class="nav-link"
                            ><i class="fa fa-inbox me-2"></i> Inbox</a
                          >
                        </li>
                        <li>
                          <a href="#" class="nav-link"
                            ><i class="fa fa-star me-2"></i> Starred</a
                          >
                        </li>
                        <li>
                          <a href="#" class="nav-link"
                            ><i class="fa fa-bookmark me-2"></i> Important</a
                          >
                        </li>
                        <li>
                          <a href="#" class="nav-link"
                            ><i class="fa fa-forward me-2"></i> Sent</a
                          >
                        </li>
                        <li>
                          <a href="#" class="nav-link"
                            ><i class="fa fa-pencil-alt me-2"></i> Drafts</a
                          >
                        </li>
                        <li>
                          <a href="#" class="nav-link"
                            ><i class="fa fa-folder me-2"></i> Spam</a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                  <!-- END INBOX MENU -->

                  <!-- BEGIN INBOX CONTENT -->
                  <div class="col-md-9">
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="d-flex">
                          <div class="centered ms-2">
                            <input type="checkbox" />
                          </div>
                          <div class="btn-group">
                            <button
                              type="button"
                              class="btn btn-default dropdown-toggle"
                              data-bs-toggle="dropdown"
                            >
                              Action <span class="caret"></span>
                            </button>
                            <ul class="dropdown-menu" role="menu">
                              <li>
                                <a href="#" class="dropdown-item"
                                  >Mark as read</a
                                >
                              </li>
                              <li>
                                <a href="#" class="dropdown-item"
                                  >Mark as unread</a
                                >
                              </li>
                              <li>
                                <a href="#" class="dropdown-item"
                                  >Mark as important</a
                                >
                              </li>
                              <li class="divider"></li>
                              <li>
                                <a href="#" class="dropdown-item"
                                  >Report spam</a
                                >
                              </li>
                              <li>
                                <a href="#" class="dropdown-item">Delete</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6 search-form">
                        <form action="#" class="text-right">
                          <div class="input-group">
                            <input
                              type="text"
                              class="form-control input-sm"
                              placeholder="Search"
                            />
                            <span class="input-group-btn">
                              <button
                                type="submit"
                                name="search"
                                class="btn btn-link btn-sm search"
                              >
                                <i class="fa fa-search"></i></button
                            ></span>
                          </div>
                        </form>
                      </div>
                    </div>

                    <hr class="m-0 p-0">

                    <div class="table-responsive">
                      <table class="table">
                        <tbody>
                          <tr class="read d-none">
                            <td class="action"><input type="checkbox" /></td>
                            <td class="action"><i class="fa fa-star"></i></td>
                            <td class="action">
                              <i class="fa fa-bookmark"></i>
                            </td>
                            <td class="name"><a href="#">Larry Gardner</a></td>
                            <td class="subject">
                              <a href="#"
                                >Lorem ipsum dolor sit amet, consectetur
                                adipisicing elit, sed
                              </a>
                            </td>
                            <td class="time">08:30 PM</td>
                          </tr>
                          <tr>
                            <td>There are 0 messages.</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <!-- END INBOX CONTENT -->

                  <!-- BEGIN COMPOSE MESSAGE -->
                  <div
                    class="modal fade"
                    id="compose-modal"
                    tabindex="-1"
                    role="dialog"
                    aria-hidden="true"
                  >
                    <div class="modal-wrapper">
                      <div class="modal-dialog">
                        <div class="modal-content">
                          <div class="modal-header bg-blue">
                            <button
                              type="button"
                              class="close"
                              data-dismiss="modal"
                              aria-hidden="true"
                            >
                              ×
                            </button>
                            <h4 class="modal-title">
                              <i class="fa fa-envelope"></i> Compose New Message
                            </h4>
                          </div>
                          <form action="#" method="post">
                            <div class="modal-body">
                              <div class="form-group">
                                <input
                                  name="to"
                                  type="email"
                                  class="form-control"
                                  placeholder="To"
                                />
                              </div>
                              <div class="form-group">
                                <input
                                  name="cc"
                                  type="email"
                                  class="form-control"
                                  placeholder="Cc"
                                />
                              </div>
                              <div class="form-group">
                                <input
                                  name="bcc"
                                  type="email"
                                  class="form-control"
                                  placeholder="Bcc"
                                />
                              </div>
                              <div class="form-group">
                                <input
                                  name="subject"
                                  type="email"
                                  class="form-control"
                                  placeholder="Subject"
                                />
                              </div>
                              <div class="form-group">
                                <textarea
                                  name="message"
                                  id="email_message"
                                  class="form-control"
                                  placeholder="Message"
                                  style="height: 120px"
                                ></textarea>
                              </div>
                              <div class="form-group">
                                <input type="file" name="attachment" />
                              </div>
                            </div>
                            <div class="modal-footer">
                              <button
                                type="button"
                                class="btn btn-default"
                                data-dismiss="modal"
                              >
                                <i class="fa fa-times"></i> Discard
                              </button>
                              <button
                                type="submit"
                                class="btn btn-primary pull-right"
                              >
                                <i class="fa fa-envelope"></i> Send Message
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- END COMPOSE MESSAGE -->
                </div>
              </div>
            </div>
          </div>
          <!-- END INBOX -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
